<template>
  <div class="content-body">
    <div class="row mt-2 mg-t-5 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Human Resources</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Attendance
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Attendance</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          v-if="checkSinglePermission('create_attendance')"
          type="button"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="$router.push('attendance-create')"
        >
          New Attendance
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select name="size" v-model="params.pageIndex" class="mr-2 mg-t-5" @change="getData()">
            <option value="15">15</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
          </select>
          <button
            type="button"
            class="btn btn-xs btn-primary mg-b-0 mr-2"
            @click="$router.push('attendance-day')"
          >
            Day Report
          </button>
          <button
            type="button"
            class="btn btn-xs btn-primary mg-b-0 mr-2"
            @click="$router.push('attendance-month')"
          >
            Month Report
          </button>
          <select v-model="params.branch" class="filter-input mr-2 mg-t-5" @change="getData()">
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in dataLists2.filter_branchs"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <SearchDayMonthComponent :showDay="false"  @clicked="monthDayClicked"></SearchDayMonthComponent>
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input type="text" class="float-right" placeholder="Search..." v-model="params.searched" @keyup="searchKeyword()" />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="exampleTable">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Emp. ID</th>
              <th class="wd-20p">Employee Name</th>
              <th class="wd-10p">Designaton</th>
              <th class="wd-10p">Present</th>
              <th class="wd-10p">Absent</th>
              <th class="wd-10p">Holiday</th>
              <th class="wd-10p">Half</th>
              <th class="wd-10p">Late</th>
              <th class="wd-5p text-center table-end-item">Action</th>
            </tr>
          </thead>
           <tbody v-if="!loading && monthAttendenceLists.length > 0">
            <tr v-for="(attendence, index) in monthAttendenceLists" :key="index">
              <th scope="row" class="table-start-item">{{pageSerialNo+index}}.</th>
              <td>{{attendence.employee_id}}</td>
              <td>{{attendence.employee_name}}</td>
              <td>{{attendence.designation}}</td>
              <td>{{attendence.present}}</td>
              <td>{{attendence.absent}}</td>
              <td>{{attendence.holiday}}</td>
              <td>{{attendence.half}}</td>
              <td>{{attendence.late}}</td>
              <td class="text-center">
                <a href="javascript:;" class="mr-3">
                  <i
                    class="fas fa-eye tx-success"
                    @click="view(attendence.id)"
                  ></i>
                </a>
              </td>
            </tr>      
          </tbody>
          <tbody v-else-if="error"><tr><td colspan="11" class="text-center">{{error_message}}</td></tr></tbody> 
           <tbody v-else-if="!loading && monthAttendenceLists.length == 0">
            <tr>
              <td  colspan="11" style="text-align:center;">
                No records found.
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td  colspan="11" style="text-align:center;">
                Loading.....
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Paginate 
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
          />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "../Services/Services";
import Paginate from 'vuejs-paginate';
import _ from 'lodash';
import SearchDayMonthComponent  from "../../../../shared/SearchDayMonthComponent";
export default {  
  components:{
    SearchDayMonthComponent,Paginate,
  },
  data(){
    return {
      params:{
        pageIndex:15,
        branch:'',
        day: new Date().getDate(),
        month:new Date().getMonth(),
        year: new Date().getFullYear(),
        searched:'',
        offset:0,
      },
      loading:false,
      error:false,
      error_message:'',
      totalPageCount:0,
      pageSerialNo:1,
    }
  },
  computed:{
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists2",
      "dataLists4",
      "dataId",
      "modalId",
      "modalMode",
      "today",
    ]),
    ...mapGetters("employees",["monthAttendenceLists"])
  },
  methods:{
    monthDayClicked(value){
      if(value){
        this.params.day = value.day;
        this.params.month = value.month;
        this.params.year = value.year
      }
      //make api call
      this.getData();
    },
    clickCallback: function(page) {
      if(page == 1){
        this.pageSerialNo = 1;
      }else{
        this.pageSerialNo = ((page-1) * this.params.pageIndex) + 1 ;
      }
      this.params.offset = (page-1) * this.params.pageIndex;
      this.getData();
    },
    searchKeyword: _.debounce(function(){
        this.getData();
      },1000),
   view(id) {
      let individual_params={
          month:this.params.month+1,
          year:this.params.year,
          id:id,
      };
      this.$store.commit("employees/setIndividualReportParams",individual_params);
      this.$router.push(`individual-attendance/${this.params.month}/${id}`);
    },
    // currentMonth(){
    //   var dateObj = new Date();
    //   this.month = (dateObj.getUTCFullYear()) + "-" + ("0" + dateObj.getMonth()+1).slice(-2);
    //   this.getData();
    // },
    getData(){
      this.loading = true;
      this.error = false;
      Services.getMonthlyAttendence(this.params).then(res=>{
          this.$store.commit("employees/setMonthAttendenceLists",res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
      }).catch(err=>{
          this.error = true;
          if(!Object.prototype.hasOwnProperty.call(err,"response.data.error_message")) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }
      }).finally(()=>{
          this.loading=false;
      });
    },
    filterByMonth(){
       this.getData();
    },
  },
  mounted(){
    this.$store.commit("getData2", `api/employee/create`);
    this.getData();
  }
};
</script>
<style scoped>
</style>